let routes = [
	{
		path: '/user',
		name: 'user-home',
		component: () => import("@/components/cabinet/access/user/service/home-component.vue")
	},
	{
		path: '/user/aviation',
		name: 'user-aviation',
		component: () => import("@/components/cabinet/access/user/service/aviation-component.vue")
	},
	{
		path: '/user/blog',
		name: 'user-blog',
		component: () => import("@/components/cabinet/access/user/service/blog-component.vue")
	},
	{
		path: '/user/orders',
		name: 'user-orders',
		component: () => import("@/components/cabinet/access/user/service/orders-component.vue")
	},
	{
		path: '/user/prices',
		name: 'user-prices',
		component: () => import("@/components/cabinet/access/user/service/prices-component.vue")
	},
	{
		path: '/user/services',
		name: 'user-services',
		component: () => import("@/components/cabinet/access/user/service/services-component.vue")
	},
	{
		path: '/user/statistics',
		name: 'user-statistics',
		component: () => import("@/components/cabinet/access/user/service/statistics-component.vue")
	},
	{
		path: '/user/settings',
		name: 'user-settings',
		component: () => import("@/components/cabinet/access/user/service/settings-component.vue")
	},
];
export default routes;