import { md5 } from 'js-md5'
import { defineStore } from 'pinia'
export const useCacheStore = defineStore({
    id: 'cache',
    state: () => ({
        data: {}
    }),
    actions: {
        async getData(request) {
            return new Promise((resolve) => {
                const now = new Date().getTime()
                const hash = md5(request)
                if (this.data[hash]) {
                    if (this.data[hash].life && this.data[hash].life > now) {
                        // Cache hit
                        resolve(this.data[hash].content)
                    } else {
                        // Cache miss
                        delete this.data[hash]
                    }
                }
                resolve(null)
            })
        },
        async writeData(request, requestData, requestLife) {
            return new Promise((resolve) => {
                const hash = md5(request)
                this.data[hash] = {
                    content: requestData,
                    life: requestLife,
                    uri: request
                }
                resolve(true)
            })
        }
    }
})
