<template>
  <div
      class="toasts-container"
      :class="{
        'top': top,
        'bottom': bottom,
        'left': left,
        'right': right
      }"
  >
    <template v-if="Array.isArray(this.toasts)">
      <toast-component
          v-for="(toast) in this.toasts"
          :key="toast.id"
          :model="toast"
          :hide="toast.hide"
          @remove="this.remove"
      >
      </toast-component>
    </template>
    <vipl-button
        class="toast-close__all"
        scheme="success"
        @click.prevent="removeAll"
        v-show="toasts !== undefined && toasts !== null && toasts.length > 1"
    >
      Очистить все
    </vipl-button>
  </div>
</template>

<script>
import { useToastsStore } from "@/components/cabinet/stores/toastsStore";
import ToastComponent from "../toasts/toastComponent";
import ViplButton from "@/components/basic/button/vipl-button";

export default {
  name: "toastsContainerComponent",
  components: {ViplButton, ToastComponent},
  props: {
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toasts: [],
      toastsController: null
    }
  },
  mounted() {
    let toastsStore = useToastsStore();
    this.toastsController = toastsStore;

    let component = this;
    toastsStore.$subscribe(function () {
      component.toasts = component.toastsController.getToasts();
    });
  },
  methods: {
    remove(id) {
      if (Array.isArray(this.toasts)) {
        let toastItem = this.toasts.find(toast => toast.id === id);
        toastItem.status = toastItem.status + ' hide';
      } else {
        this.toasts.status = this.toasts.status + ' hide';
      }

      let component = this;
      this.sleep(1000).then(function() {
        component.toastsController.removeToast(id);
      });
    },
    removeAll() {
      this.toastsController.removeToasts();
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>

<style lang="sass" scoped>
@import "toasts"
</style>