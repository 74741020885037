<template>
  <cabinet-component></cabinet-component>
</template>

<script>
import CabinetComponent from "@/components/cabinet/cabinetComponent.vue";

export default {
  name: 'viplaneApp',
  components: {CabinetComponent},
}
</script>

<style>

</style>
