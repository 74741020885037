import { defineStore } from 'pinia';

export const useLightboxStore = defineStore({
	id: 'lightbox',
	state: () => ({
		images: [],
		index: 0,
		active: false
	}),
	actions: {
		getImage(id) {
			return this.images.filter(image => image.id === id);
		},
		addImage(image) {
			this.images.push(image);
			return image;
		},
		removeImage(id) {
			this.images = this.images.filter(image => image.id !== id);
		},
		getImages() {
			return this.images;
		},
		removeImages() {
			this.images = [];
		},
		show() {
			this.active = true;
		},
		hide() {
			this.active = false;
		},
		toggle() {
			this.active = !this.active;
		},
		next() {
			this.index++;
		},
		prev() {
			if (this.index-- === 0) {
				this.index = 0;
			} else {
				this.index--;
			}
		}
	}
})