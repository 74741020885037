import axios from "axios";
import {Logon} from "@/components/cabinet/tools/logonTool";
import {useLogonStore} from "@/components/cabinet/stores/logonStore";
import {useToastsStore} from "@/components/cabinet/stores/toastsStore";
import { i18n } from "@/components/i18n/i18n";

export const requestType = {
	POST: 'post',
	DELETE: 'delete',
	GET: 'get',
	PUT: 'put'
}

export const returnMode = {
	VALUE: 'value',
	ALL: 'all',
	STATUS: 'status'
}

export class requestTool {
	basePath = process.env.VUE_APP_API_SERVICE;
	requestMethod = '';
	requestData = {};
	responseType = '';
	responseEncoding = 'utf8';
	toastsController = useToastsStore();
	logonController = useLogonStore();

	/**
	 * Prepare request for executing
	 * Available methods:
	 * GET
	 * POST
	 * PUT
	 * DELETE
	 * Arguments:
	 * Method - specify method to conduit request
	 * Data - data to send (you could to not specify data at all)
	 * ResponseType - should it be JSON or anything else supported by Axios Library
	 * @param method {string}
	 * @param data {object}
	 * @param responseType {string}
	 * @return {Promise<object>}
	 */
	prepareRequest(method, data = {}, responseType = 'json') {
		switch (method) {
			case requestType.POST:
				this.requestMethod = requestType.POST;
				this.responseType = responseType;
				this.requestData = data;
				break;

			case requestType.DELETE:
				this.requestMethod = requestType.DELETE;
				this.responseType = responseType;
				break;

			case requestType.GET:
				this.requestMethod = requestType.GET;
				this.responseType = responseType;

				try {
					let dataString = '?';
					for (let [key, value] of Object.entries(data)) {
						dataString += key;
						dataString += "=";
						dataString += value;
						dataString += "&";
					}
					dataString = dataString.slice(0, -1);
					this.requestData = dataString;
				} catch (error) {
					throw 'Parse error: ' + error;
				}

				break;

			case requestType.PUT:
				this.requestMethod = requestType.PUT;
				this.responseType = responseType;
				this.requestData = data;
				break;
		}
	}

	/**
	 * Process the given response
	 * @param requestToProcess {string}
	 * @param nullSuccessful {object}
	 */
	async processResponse(requestToProcess, nullSuccessful = false) {
		return new Promise((resolve, reject) => {
			requestToProcess.then(value => {
				resolve(value.data.data);
			}).catch(error => {
				if (nullSuccessful) {
					if (error.error === 'Not Found') {
						resolve([]);
					} else {
						reject(error);
					}
				} else {
					reject(error);
				}
			});
		});
	}

	/**
	 * Execute prepared request (always async).
	 * If request successfully executed, the previous
	 * data will be cleared, and you have to prepare a request again
	 * @param path {string}
	 * @param returnSettings {object}
	 * @param contentType {string}
	 * @param basePath {string}
	 * @return {Promise<object>}
	 */
	async doRequest(path, returnSettings, contentType = "application/json", basePath = this.basePath) {
		return new Promise((resolve, reject) => {
			let logon = new Logon();
			let returnObject = {};
			let requestURL = basePath + path;
			if (this.requestMethod === requestType.GET) {
				requestURL += this.requestData;
			}
			axios ({
				method: this.requestMethod,
				url: requestURL,
				data: this.requestData,
				responseType: this.responseType,
				responseEncoding: this.responseEncoding,
				headers: {
					Authorization: logon.getTokenType() + " " + logon.getToken(),
					"Content-Type": contentType
				}
			}).then( function (response) {
				switch (returnSettings) {
					case returnMode.ALL:
						returnObject = response;
						break;

					case returnMode.VALUE:
						returnObject = response.data;
						break;

					case returnMode.STATUS:
						returnObject.status = response.status;
						returnObject.statusText = response.statusText;
						break;
				}

				resolve(returnObject);
			}).catch(error => {
				let toast = {};
				toast.payload = error;

				if (error === undefined || error.response === undefined) {
					toast.title = i18n.t('alerts.error');
					toast.description = i18n.t('alerts.error_500');
					toast.status = 'danger';
					toast.time = 4000;
					this.toastsController.addToast(toast);

					reject(error);
				} else {
					switch (error.response.status) {
						case 401:
							this.logonController.setStatus('unlogged');
							toast.title = i18n.t('alerts.attention');
							toast.description = i18n.t('alerts.error_401');
							toast.status = 'danger';
							toast.time = 4000;
							this.toastsController.addToast(toast);
							break;

						case 500:
							toast.title = i18n.t('alerts.error');
							toast.description = i18n.t('alerts.error_500');
							toast.description += '<br>';
							toast.description += error.response.data.message;
							toast.status = 'danger';
							toast.time = 4000;
							this.toastsController.addToast(toast);
							break;

						default:
							break;
					}
					reject(error.response.data);
				}
			});
		});
	}
}