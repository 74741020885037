<template>
  <VueEasyLightbox
      :visible="active"
      :imgs="images"
      :index="index"
      @hide="lightboxController.hide()"
  >
  </VueEasyLightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import {useLightboxStore} from "@/components/cabinet/stores/lightboxStore";

export default {
  name: "lightboxComponent",
  components: {
    VueEasyLightbox
  },
  data() {
    return {
      images: [],
      active: false,
      index: 0,
      lightboxController: null
    }
  },
  mounted() {
    let lightboxStore = useLightboxStore();
    this.lightboxController = lightboxStore;

    let component = this;
    lightboxStore.$subscribe(function () {
      component.images = component.lightboxController.getImages();
      component.active = component.lightboxController.active;
      component.index = component.lightboxController.index;
    });
  },
}
</script>

<style scoped>

</style>