<template>
  <main>
    <section class="mainblock">
      <sidebarComponent
          ref="mainSidebar"
          v-if="!this.uiStore.authRequired"
      ></sidebarComponent>
      <div
          :style="'margin-left:' + contentPadding + ';' + 'width: 100%; max-width: 1600px; position: relative;'"
          ref="mainContent"
      >
        <toasts-container-component right top></toasts-container-component>
        <lightbox-component></lightbox-component>
        <router-view></router-view>
      </div>
    </section>
  </main>
</template>

<script>
import sidebarComponent from "@/components/cabinet/blocks/sidebarComponent";
import "@/components/cabinet/tools/logonTool";
import {queryTool} from "@/components/cabinet/tools/queryTool";
import {useUIStorage} from "@/components/cabinet/stores/uiStore";
import {useLogonStore} from "@/components/cabinet/stores/logonStore";
import ToastsContainerComponent from "@/components/additionals/toasts/toastsContainerComponent.vue";
import LightboxComponent from "@/components/additionals/lightbox/lightboxComponent.vue";

export default {
  name: "cabinetComponent",
  components: {
    LightboxComponent,
    ToastsContainerComponent,
    sidebarComponent,
  },
  data() {
    return {
      contentPadding: '0px',
      collapsedSidebar: false,
      authRequired: true,
      queryTools: new queryTool(),
      uiStore: useUIStorage(),
      logonStore: useLogonStore()
    }
  },
  props: [
      'currentComponent'
  ],
  computed: {
    modifiedComponent() {
      return this.currentComponent;
    }
  },
  mounted() {
    this.logonStore.setupData();
    this.uiStore.setupData();

    this.uiStore.checkAuth();

    let component = this;
    component.resizeContentZone();
    window.addEventListener("resize", function() {
      component.resizeContentZone();
    });

    this.uiStore.$subscribe(function(mutation, state) {
      if (state.sidebarCollapse) {
        component.sleep(2000).then(function () {
          component.resizeContentZone();
        });
      } else {
        component.sleep(2000).then(function () {
          component.resizeContentZone();
        });
      }
    });
  },
  updated() {
    this.resizeContentZone();
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    resizeContentZone: function () {
      if (this.$refs.mainSidebar !== undefined && this.$refs.mainSidebar !== null && this.$refs.mainSidebar.$el !== undefined && this.$refs.mainSidebar.$el !== null) {
        this.contentPadding = (window.innerWidth - (window.innerWidth - this.$refs.mainSidebar.$el.clientWidth)) + 'px';
      }
    }
  }
}
</script>

<style lang="sass">

</style>