import { createI18n } from "vue-i18n";
import { messages } from "@/components/i18n/languages/languageManager";

const instance = createI18n({
	locale: 'RUS',
	fallbackLocale: 'ENG',
	messages
});

export default instance;

export const i18n = instance.global;