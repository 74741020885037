import { defineStore } from 'pinia';
import {useLogonStore} from "@/components/cabinet/stores/logonStore";
import {queryTool} from "@/components/cabinet/tools/queryTool";

export const useUIStorage = defineStore({
	id: 'ui',
	state: () => ({
		sidebarCollapse: false,
		sidebarMenus: [],
		currentSidebarMenu: 0,
		currentComponent: 'home',
		authRequired: true,
		logonStore: useLogonStore(),
		queryTools: new queryTool(),
		lang: {
			name: 'RUS'
		},
		currency: {
			name: 'RUB'
		},
		previewModal: false,
		previewModalItem: {},
		previewModalBig: false,
		previewModalBigItem: {},
		copyModal: false,
		treeModal: false
	}),
	actions: {
		setDocumentTitle(title) {
			document.title = title;
		},
		addSidebarMenu(menu) {
			this.sidebarMenus.push(menu);
			this.saveData();
		},
		removeSidebarMenu(index) {
			this.sidebarMenus.splice(index, 1);
			this.saveData();
		},
		setCurrentMenu(index) {
			this.currentSidebarMenu = this.sidebarMenus[index];
			this.saveData();
		},
		setSidebarMenuByName(name) {
			let currentMenusMap = this.sidebarMenus.map(a => a.name);
			this.currentSidebarMenu = currentMenusMap.indexOf(name);
			this.saveData();
		},
		checkAuth () {
			this.logonStore.setupData();
			if (this.logonStore.token === undefined || this.logonStore.token === null) {
				this.authRequired = true;
			} else {
				let component = this;
				this.queryTools.getLanguages().then(function() {
					component.authRequired = false;
				});
			}
		},
		setupData() {
			let uiItem = JSON.parse(localStorage.getItem("uiManager"));
			if (uiItem !== undefined && uiItem !== null) {
				this.sidebarCollapse = uiItem.sidebarCollapse;
			} else {
				let uiItem = {};
				uiItem.sidebarCollapse = this.sidebarCollapse;
				localStorage.setItem("uiManager", JSON.stringify(uiItem));
			}
		},
		toggleSidebarCollapse() {
			this.sidebarCollapse = !this.sidebarCollapse;
			this.saveData();
		},
		setSidebarCollapse(state) {
			this.sidebarCollapse = state;
			this.saveData();
		},
		saveData() {
			let savingData = {};
			savingData = Object.assign(savingData, this.$state);
			delete savingData.logonStore;
			delete savingData.queryTools;
			localStorage.setItem("uiManager", JSON.stringify(savingData));
		}
	}
})