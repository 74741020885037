import { createWebHistory, createRouter } from "vue-router";

import userRoutes from "@/components/cabinet/access/user/routes";
import managerRoutes from "@/components/cabinet/access/manager/routes";
import editRoutes from "@/components/cabinet/access/edit/routes";

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import("@/components/cabinet/cabinetComponent"),
		props: route => ({
			access: route.params.access
		})
	},
	{
		path: '/login',
		name: 'login',
		component: () => import("@/components/additionals/modal/login/modalLogin")
	}
];
routes = routes.concat(userRoutes);
routes = routes.concat(managerRoutes);
routes = routes.concat(editRoutes);

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
export default router