export let rus_lang = {
	lang_code: 'RUS',
	titles: {
		category: 'Категория',
		information_and_rights: 'Информация и права',
		photos_and_schemes: 'Фотографии и схемы',
		airport_description: 'Описание аэропорта',
		terminal_description: 'Описание терминала',
		provider_description: 'Описание провайдера',
		alerts: 'Уведомления',
		notes: 'Примечания',
		airport: 'Аэропорт',
		provider: 'Провайдер',
		entity: 'Сущность',
		variable_block: 'Блок переменных',
		show: 'Отображение',
		watch_rights: 'Права просмотра',
		source_rights: 'Права доступа',
		client_types: 'Права пользователей',
		city: 'Город',
		type: 'Тип',
		country: 'Страна',
		currency: 'Валюта',
		variable: 'Переменная',
		iata: 'IATA',
		icao: 'ICAO',
		name: 'Название',
		edit: 'Редактирование',
		drop: 'Сброс',
		types: 'Типы',
		value: 'Значение',
		time_zone: 'Временная зона',
		latitude: 'Широта',
		longtitude: 'Долгота',
		airport_name: 'Заголовок аэропорта',
		terminal_name: 'Заголовок терминала',
		provider_name: 'Заголовок провайдера',
		description_name: 'Заголовок описания',
		block_name: 'Заголовок блока',
		variable_name: 'Заголовок переменной',
		element_name: 'Заголовок элемента',
		language: 'Язык',
		all_elements: 'Все',
		image_name: 'Заголовок изображения',
		airport_editor: 'Редактор аэропорта',
		terminal_editor: 'Редактор терминала',
		provider_editor: 'Редактор провайдера',
		currency_editor: 'Редактор валюты',
		city_editor: 'Редактор города',
		variable_block_editor: 'Редактор блока переменных',
		comfort_category_editor: 'Редактор категории удобств',
		variable_editor: 'Редактор переменнной',
		services_editor: 'Редактор услуг',
		service_editor: 'Редактор услуги',
		comfort_editor: 'Редактор удобств',
		editor: 'Редактор',
		forms: 'Формы',
		all: 'Все',
		importance: 'Важность',
		start_date: 'Дата начала',
		end_date: 'Дата окончания',
		auto_alert: 'Пролонгация уведомления',
		auto_alert_check: 'Автопролонгация',
		terminal: 'Терминал',
		air_lines: 'Воздушные линии',
		international_flight_short: 'МВЛ',
		domestic_flight_short: 'ВВЛ',
		flight_direction: 'Направление рейсов',
		flight_arrival: 'Прилет',
		flight_departure: 'Вылет',
		flight_transit: 'Транзит',
		flight_common: 'Общие',
		flight_additionals: 'Допуслуги',
		code: 'Кодовое обозначение',
		words: 'Ключевые слова',
		default_editor: 'Редактор',
		airport_object: 'Объект аэропорта',
		city_object: 'Объект города',
		terminal_object: 'Объект терминала',
		provider_object: 'Объект провайдера',
		comfort_object: 'Объект удобства',
		block_object: 'Объект блока',
		variable_object: 'Объект переменной',
		currency_object: 'Объект валюты',
		currency_form_type: 'Тип наценки',
		currency_absolute: 'Значение',
		currency_percentage: 'Значение',
		currency_fixed: 'Значение',
		currency_percent: 'Значение',
		currency_total: 'Значение',
		currency_name: 'Название валюты',
		currency_code: 'Код валюты',
		currency_crb: 'Курс ЦБ РФ',
		currency_in_widget: 'В виджете',
		comfort_category: 'Категория удобств',
		all_photos: 'Блок: все фотографии',
		media_object: 'Блок: медиа-объект ',
		description_settings: 'Настройки доступа описания',
		notes_settings: 'Основные настройки примечания',
		alerts_settings: 'Основные настройки уведомления',
		provider_status: 'Статус провайдера',
		comforts: 'Перечень удобств',
		login: 'Логин',
		password: 'Пароль',
		connections_providers: 'Доступы к терминалам',
		connections_terminals: 'Доступы к терминалу',
		connections_airports: 'Доступы к аэропорту',
		main_services: 'Основные услуги',
		additional_services: 'Дополнительные услуги',
		auth: 'Логин и пароль',
		authsite: 'Доступ от сайта',
		variables: 'Информация',
		additionals: 'Допуслуги',
		site: 'Сайт',
		common_settings: 'Общие настройки',
		connections_rights: 'Права доступа',
		position: 'Позиция',
		required: 'Необходимо',
		multi: 'Мульти',
		columns: 'Колонки',
		divider: 'Разделитель',
		offset: 'Отступ',
		params: 'Параметры',
		elements_items: 'Элементы выбора',
		workers_photo: 'Фото сотрудника',
		tech_name: 'Техническое название',
		description: 'Описание',
		primary: 'Основной',
		workers: {
			title: 'Сотрудники',
			name: 'Имя',
			second_name: 'Фамилия',
			third_name: 'Отчество',
			job: 'Должность',
			role: 'Роль',
			email: 'Email',
			phone: 'Телефон',
			documents: 'Документы',
			more: 'Подробнее',
			date: 'Дата',
			gender: 'Пол'
		},
		providers: {
			add_services: 'Допуслуги провайдера'
		},
		terminals: {
			departure: 'Вылет',
			arrival: 'Прилет',
			transit: 'Транзит',
			international_flight_short: 'МВЛ',
			domestic_flight_short: 'ВВЛ',
			flight_lines: 'Воздушные линии'
		},
		services: {
			add_services: 'Дополнительные услуги',
			services: 'Услуги',
			service: 'Услуга',
			main_services: 'Основные услуги',
			object: 'Объект услуги',
			main: 'Основной',
			title: 'Заголовок услуги',
			type: 'Тип услуги',
			direction: 'Направление'
		},
		default_value: 'По умолчанию'
	},
	placeholders: {
		search: 'Поиск',
		choose: 'Выберите',
		search_site: 'Поиск по сайту',
		select_city: 'Выберите город',
		select_country: 'Выберите страну',
		select_currency: 'Выберите валюту',
		select_iata: 'Выберите IATA',
		select_icao: 'Выберите ICAO',
		select_lang: 'Выберите язык',
		select_airport: 'Выберите аэропорт',
		select_terminal: 'Выберите терминал',
		select_provider: 'Выберите провайдера',
		select_service_type: 'Выберите тип услуги',
		select_service: 'Выберите услугу',
		select_direction: 'Выберите направление',
		provider_status: 'Статус провайдера',
		select_variable_block: 'Выберите блок',
		login: 'Логин',
		password: 'Пароль'
	},
	alerts: {
		success: 'Успех!',
		failure: 'Провал!',
		error: 'Ошибка!',
		attention: 'Внимание!',
		success_save: 'Успешно сохранено',
		error_save: 'Произошла ошибка при сохранении',
		success_delete: 'Успешно удалено',
		error_delete: 'Произошла ошибка при удалении',
		too_big_file: 'Слишком большой файл',
		unsupported_format: 'Неподдерживаемый формат',
		success_upload: 'Успешно загружено',
		error_500: 'Серверная ошибка, вероятно, сервер перегружен',
		error_401: 'Необходимо авторизоваться'
	},
	buttons: {
		save: 'Сохранить',
		cancel: 'Отменить',
		delete: 'Удалить',
		preview: 'Предпросмотр',
		clear: 'Очистить',
		confirm: 'Подтвердить',
		all: 'Все',
		all_elements: 'Все элементы',
		add: 'Добавить',
		add_airport: 'Добавить аэропорт',
		add_description: 'Добавить описание',
		add_note: 'Добавить примечание',
		add_alert: 'Добавить уведомление',
		add_element: 'Добавить элемент',
		logs: 'Логи',
		on: "ВКЛ",
		off: 'ВЫКЛ',
		back: 'Назад',
		reset: 'Сброс',
		edit: 'Редактировать',
		no_elements: 'Нет элементов',
		loading: 'Загрузка...',
		add_terminal: 'Добавить терминал',
		add_provider: 'Добавить провайдера',
		add_provider_connection: 'Добавить доступ',
		add_variable_worker: 'Добавить сотрудника',
		add_service: 'Добавить услугу'
	},
	headers: {
		settings: 'Настройки',
		photos_and_schemes: 'Фотографии и схемы',
		photos: 'Фотографии',
		schemes: 'Схемы',
		driveway: 'Проезд',
		alerts: 'Уведомления',
		notes: 'Примечания',
		comforts: 'Перечень удобств',
		login_and_password: 'Логин и пароль',
		login_and_password_provider_header: 'Настройки доступа к кабинету провайдера',
		login_and_password_site: 'Доступ от сайта',
		login_and_password_site_provider_header: 'Доступ от сайта провайдера'
	},
	elements: {
		created: 'Создано',
		until: 'До',
		autoprolongation: 'Автопродление'
	},
	cases: {
		of_airport: 'аэропорта'
	},
	badges: {
		importance_high: 'В',
		importance_medium: 'С',
		importance_low: 'Н'
	},
	preview: {
		main_service: {
			titles: {
				service: 'Услуга',
				not_selected: 'Услуга не выбрана',
				site_description: 'Инфо',
				procedure: 'Процедура',
				site_cancel: 'Условия отмены',
				arrival: 'Прилет',
				departure: 'Вылет',
				transit: 'Транзит',
				cost: 'Стоимость'
			},
			placeholders: {

			},
			buttons: {
				select_service: 'Выбрать услугу',
				more: "Подробнее",
				order: "Заказать",
				all_services: "Все",
				vip_services: "VIP-зал",
				fast_track_services: "Fast-Track",
				meet_n_assist_services: "Meet&Assist",
				bussiness_services: "Бизнес-зал",
				other_services: "Прочие",
				back_to_form: "К форме"
			}
		},
		price: {
			adult: "Взрослый",
			adults: "Взрослые",
			adults_age: "От 12 лет",
			child: "Ребёнок",
			children: "Дети",
			children_age: "От 2 до 12 лет",
			babies: 'Младенцы',
			babies_age: "До 2 лет",
			people: "Человек",
			peoples: "Человека",
			people_short: "Чел",
			age: "Лет",
			like_adult: "Как взрослый",
			order: "Заказ",
			urgency: "Срочность",
			discount: "Скидка"
		}
	}
};