<template>
  <button
      :class="classes"
      :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "vipl-button",
  data() {
    return {
      classes: {
        'vipl-button': true,
        'vipl-button-default': true,
        'vipl-button-regular': this.regular,
        'vipl-button-xl': this.xl,
        'vipl-button-center': this.center,
        'vipl-button-sm': this.sm,
        'vipl-button-iconed': this.iconed || this.iconed_left || this.iconed_null,
        'vipl-button-iconed-left': this.iconed_left,
        'vipl-button-iconed-null': this.iconed_null,
        'vipl-button-block': this.block
      }
    }
  },
  beforeMount() {
    if (this.scheme)
      this.classes['vipl-button-' + this.scheme] = true;
    if (this.hoverScheme)
      this.classes['vipl-button-hover-' + this.hoverScheme] = true;
    if (this.activeScheme)
      this.classes['vipl-button-active-' + this.activeScheme] = true;
  },
  props: {
    xl: {
      type: Boolean,
      default: false
    },
    sm: {
      type: Boolean,
      default: false
    },
    regular: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    iconed: {
      type: Boolean,
      default: false
    },
    iconed_left: {
      type: Boolean,
      default: false
    },
    iconed_null: {
      type: Boolean,
      default: false
    },
    scheme: {
      type: String,
      default: ''
    },
    hoverScheme: {
      type: String,
      default: ''
    },
    activeScheme: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>