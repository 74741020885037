import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from "@/router/router";
import App from './App.vue';
import CKEditor from "@ckeditor/ckeditor5-vue";
import i18nInstance from "@/components/i18n/i18n";

const pinia = createPinia()
const app = createApp(App);

app.use(i18nInstance);
app.use(CKEditor);
app.use(pinia);
app.use(router);
app.mount('#app');