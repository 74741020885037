let routes = [
	{
		path: '/edit',
		name: 'edit-home',
		component: () => import("@/components/cabinet/access/edit/edit-modal.vue")
	},
	{
		path: '/edit/new',
		name: 'edit-empty-tab',
		component: () => import("@/components/cabinet/access/edit/edit-modal.vue")
	},
	{
		path: '/edit/:object/',
		name: 'edit-object-new',
		redirect: to => {
			return {
				name: 'edit-object-id',
				params: {
					id: 'new',
					object: to.params.object
				}
			}
		},
		component: () => import("@/components/cabinet/access/edit/edit-modal.vue")
	},
	{
		path: '/edit/:object/:id',
		name: 'edit-object-id',
		component: () => import("@/components/cabinet/access/edit/edit-modal.vue")
	}
];
export default routes;