import { useLogonStore } from "@/components/cabinet/stores/logonStore";

// Anything you need for login and anything else
// A singleton, btw
export class Logon {
	"use strict";
	instance = null;

	constructor() {
		this.createInstance();
	}

	createInstance() {
		if (this.instance) {
			return this.instance;
		}
		this.instance = this;
	}

	getInstance() {
		return this.instance || new Logon();
	}

	async doLogin(login, password) {
		const logonStore = useLogonStore();
		logonStore.setupData();
		return await logonStore.doLogin(login, password);
	}

	checkToken() {
		const logonStore = useLogonStore();
		logonStore.setupData();
		return logonStore.token !== null;
	}

	getToken() {
		const logonStore = useLogonStore();
		logonStore.setupData();
		return logonStore.token;
	}

	getTokenType() {
		const logonStore = useLogonStore();
		logonStore.setupData();
		return logonStore.token_type;
	}
}