import { defineStore } from 'pinia';
import { i18n } from "@/components/i18n/i18n";

export const useToastsStore = defineStore({
	id: 'toasts',
	state: () => ({
		toasts: [],
		toastsID: 0
	}),
	actions: {
		getToast(id) {
			return this.toasts.filter(toast => toast.id === id);
		},
		addToast(toast) {
			toast.id = this.toastsID;
			this.toastsID++;
			this.toasts.push(toast);
			return toast;
		},
		removeToast(id) {
			this.toasts = this.toasts.filter(toast => toast.id !== id);
		},
		getToasts() {
			return this.toasts;
		},
		removeToasts() {
			this.toasts = [];
		},
		showDefaultError() {
			let toast = {};
			toast.title = i18n.t('alerts.error');
			toast.time = 4000;
			toast.status = 'danger';
			this.toasts.push(toast);
		},
		showDefaultSuccess() {
			let toast = {};
			toast.title = i18n.t('alerts.success');
			toast.time = 4000;
			toast.status = 'success';
			this.toasts.push(toast);
		}
	}
})