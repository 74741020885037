import { rus_lang } from "@/components/i18n/languages/rusLanguage";

let languagesObject = {};
let languagesArray = [rus_lang];

for (let i = 0; i < languagesArray.length; i++) {
	languagesObject[languagesArray[i].lang_code] = languagesArray[i];
}

let messages = languagesObject;

export { messages };