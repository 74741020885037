let routes = [
	{
		path: '/manager',
		name: 'manager-home',
		component: () => import("@/components/cabinet/access/manager/service/homeComponent.vue")
	},
	{
		path: '/manager/airports',
		name: 'manager-airports',
		component: () => import("@/components/cabinet/access/manager/service/airports-component.vue")
	},
	{
		path: '/manager/currencies',
		name: 'manager-currencies',
		component: () => import("@/components/cabinet/access/manager/service/currencies-component.vue")
	},
	{
		path: '/manager/providers',
		name: 'manager-providers',
		component: () => import("@/components/cabinet/access/manager/service/providers-component.vue")
	},
	{
		path: '/manager/validator',
		name: 'manager-validator',
		component: () => import("@/components/cabinet/access/manager/service/validator-component.vue")
	},
	{
		path: '/manager/validator/:airport',
		name: 'manager-validator-airport',
		component: () => import("@/components/cabinet/access/manager/service/validator-component.vue")
	},
	{
		path: '/manager/datatree',
		name: 'manager-datatree',
		component: () => import("@/components/cabinet/access/manager/service/datatree-component.vue")
	}
];
export default routes;