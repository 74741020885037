import { defineStore } from 'pinia';
import { requestTool, returnMode, requestType } from "@/components/cabinet/tools/requestTool";
import router from "@/router/router";
import {useUIStorage} from "@/components/cabinet/stores/uiStore";

export const useLogonStore = defineStore({
	id: 'logon',
	state: () => ({
		login: null,
		password: null,
		token: null,
		token_type: null,
		group: null,
		components: null,
		status: null,
		uiStore: useUIStorage()
	}),
	actions: {
		setupData() {
			let logonItem = JSON.parse(localStorage.getItem("logon"));
			if (logonItem !== undefined && logonItem !== null) {
				this.login = logonItem.login;
				this.password = logonItem.password;
				this.token = logonItem.token;
				this.token_type = logonItem.token_type;
				this.group = logonItem.group;
				this.components = logonItem.components;
				this.status = logonItem.status;
			} else {
				let logonItem = {};
				logonItem.login = this.login;
				logonItem.password = this.password;
				logonItem.token = this.token;
				logonItem.token_type = this.token_type;
				logonItem.group = this.group;
				logonItem.components = this.components;
				logonItem.status = this.status;

				localStorage.setItem("logon", JSON.stringify(logonItem));
			}

			if (logonItem !== undefined && logonItem !== null) {
				if (logonItem.status !== 'logged' || logonItem.token === null)
					router.push({name: 'login'});
			} else {
				router.push({name: 'login'});
			}
		},
		async doLogin(username, password) {
			return new Promise((resolve, reject) => {
				let data = {
					"device_name": "service",
					"email": username,
					"password": password
				};

				let requestPath = '/api/v1/auth/login';

				let requestTools = new requestTool();
				requestTools.prepareRequest(requestType.POST, data);
				let requestPromise = requestTools.doRequest(requestPath, returnMode.VALUE);

				requestPromise.then(value => {
					let responseObject = value;

					if (responseObject.token === undefined || responseObject.token === null)
						throw 'Token invalid';

					let logon = {
						"login": username,
						"token": responseObject.token,
						"token_type": responseObject.token_type,
						"password": "",
						"components": "",
						"status": "logged",
						"group": ""
					};
					localStorage.setItem("logon", JSON.stringify(logon));

					router.push({name: 'home'});
					resolve(true);
				}, reason => {
					reject(reason);
				});
			});
		},
		logout() {
			this.token = null;
			this.login = null;
			this.group = null;
			this.status = null;
			this.components = null;
			this.password = null;
		},
		setStatus(status) {
			this.status = status;
			if (status !== 'logged') {
				this.uiStore.authRequired = true;
				router.push({name: 'login'});
			} else {
				this.uiStore.authRequired = false;
				router.push({name: 'home'});
			}
		},
		getStatus() {
			return this.status;
		},
		saveData() {
			let logonItem = {};
			logonItem.login = this.login;
			logonItem.password = this.password;
			logonItem.token = this.token;
			logonItem.token_type = this.token_type;
			logonItem.group = this.group;
			logonItem.components = this.components;
			logonItem.status = this.status;

			localStorage.setItem("logon", JSON.stringify(logonItem));
		}
	},
});