<template>
  <div
      class="toast"
      :class="model.status"
  >
    <button
        class="toast-close button button-success button-default button-regular button-iconed button-iconed-null"
        @click.prevent="this.$emit('remove', model.id)"
    >
      <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.20805L7.18395 6L12 10.7919L10.796 12L5.97993 7.20805L1.20401 12L0 10.7919L4.77592 6L0 1.20805L1.20401 0L5.97993 4.79195L10.796 0L12 1.20805Z" fill="#000"/></svg>
    </button>
    <div class="toast-title" v-if="model.title !== undefined && model.title.length > 0">
      {{ model.title }}
    </div>
    <div class="toast-description"
         v-if="model.description !== undefined && model.description.length > 0"
         v-html="model.description"
    >
    </div>
    <div class="toast-actions" v-if="model.status === 'danger'">
      <button
          class="vipl-button vipl-button-success vipl-button-default vipl-button-regular vipl-button-sm"
          @click.prevent="copyData"
          style="margin-top: 15px"
      >
        Копировать
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "toastComponent",
  props: {
    model: {
      type: Object,
      default: null
    }
  },
  mounted() {
    let component = this;
    this.sleep(this.model.time).then(function() {
      component.$emit('remove', component.model.id);
    });
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    copyData() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(JSON.stringify(this.model))
        alert("Успешно скопировано")
      } else {
        const tempElement = document.createElement("input")
        tempElement.setAttribute("id", "clipCopy")
        tempElement.setAttribute("type", "hidden")
        var copyText = document.getElementById("clipCopy")
        copyText.select()
        document.execCommand("copy")
        document.getElementById("clipCopy").outerHTML = ""
        alert("Успешно скопировано")
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "toasts"
</style>